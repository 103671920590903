// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.content-section {
  width: 100%;
  position: relative;

  .home-nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      display: inline-block;
      width: 23%;
      border: solid 1px #fff;
      padding: 7px;
      margin-bottom: rem(30px);
      text-decoration: none;
      color: $main-text-color;

      &:hover {
        span {
          background-color: $brand-color;

          &:before {
            background-position: center 100%;
          }
        }
      }

      img {
        width: 100%;
        height: auto;
        display: block;
      }

      span {
        display: block;
        height: rem(38px);
        line-height: rem(38px);
        overflow: hidden;
        background: #fff;
        padding: 0 0 0 rem(34px);
        position: relative;
        font-size: rem(14px);
        transition: background-color .25s ease-in-out;

        &:before {
          content: '';
          width: 30px;
          height: 11px;
          background: image('ico-arrow.png') center 0 no-repeat;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 14px;
        }
      }

      .empty {
        background: #fff;
        width: 100%;
        height: 100%;;
      }
    }
  }
}
