// -----------------------------------------------------------------------------
// This file contains styles that are specific to the post-launch page.
// -----------------------------------------------------------------------------
.content-chart {
  width: 100%;
  min-height: rem(460px);
  background: #fff;
  padding: rem(14px);
  position: relative;

  &.empty {
    background-image: image('ico-chart.png');
    background-position: center;
    background-repeat: no-repeat;
  }

  .filter-line{
    .btn-filter {
      &:before {
        background-image: image('ico-filter.png');
        width: 14px;
        height: 15px;
        margin: 5px 6px;
      }
    }

    .filter-label {
      display: inline-block;
      vertical-align: top;
      margin-left: 10px;
      width: calc(100% - 360px);
      padding: 5px 0;

      i {
        display: inline-block;
        width: 3px;
        height: 7px;
        background: image('ico-arrow.png') 0 100% no-repeat;
        background-size: 3px 14px;
        margin: 0 8px;
      }
    }

    .sorting {
      display: none;
      position: absolute;
      width: 262px;
      top: 26px;
      right: 40px;

      &.active {
        display: inline-block;
      }

      &:before {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background: #b2b2b2;
      }

      a {
        display: inline-block;
        position: absolute;
        transform: translateX(-50%);
        color: #b2b2b2;
        padding-top: 10px;
        white-space: nowrap;
        text-decoration: none;

        &:nth-child(2) {
          margin-left: 33.333%;
        }

        &:nth-child(3) {
          margin-left: 66.666%;
        }

        &:nth-child(4) {
          margin-left: 100%;
        }

        &:before {
          content: '';
          display: block;
          height: 8px;
          width: 8px;
          background: #b2b2b2;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 50%;
          margin: -4px 0 0 -4px;
        }

        &.active {
          color: $main-text-color;

          &:before {
            height: 14px;
            width: 14px;
            background: $brand-color;
            margin: -7px 0 0 -7px;
          }
        }
      }
    }
  }
}
