// -----------------------------------------------------------------------------
// This file contains all styles related to the button components.
// -----------------------------------------------------------------------------
.btn-ico {
  border: solid 1px $border-color;
  border-radius: $border-radius;
  width: 28px;
  height: 26px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
  transition: background-color .25s ease-in-out;

  &:hover {
    background-color: $brand-color;

    &:before {
      background-position: 0 100%;
    }
  }

  &:before {
    content: '';
    display: block;
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 7px;
    height: 16px;
    margin: 4px 10px;
    cursor: pointer;
  }

  &.btn-info {
    &:before {
      background-image: image('ico-info.png');
      width: 7px;
      height: 16px;
      margin: 4px 10px;
    }
  }
}

.btn {
  border: none;
  border-radius: $border-radius;
  padding: 7px 20px 7px 34px;
  margin: 0;
  cursor: pointer;
  outline: none;
  display: inline-block;
  vertical-align: top;
  background-color: $white;
  transition: background-color .25s ease-in-out;
  position: relative;
  white-space: nowrap;

  &:hover {
    background-color: $brand-color;

    &:before {
      background-position: center 100%;
    }
  }

  &:before {
    content: '';
    width: 30px;
    height: 11px;
    background: image('ico-arrow.png') center 0 no-repeat;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 9px;
  }
}
