// -----------------------------------------------------------------------------
// This file contains all styles related to the main navigation.
// -----------------------------------------------------------------------------
nav {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 270px;
  background: $white;
  margin-top: $header-height;
  height: calc(100% - #{$header-height} - #{$footer-height});
  z-index: 100;
  transform: translateX(-100%);
  transition: transform 0.25s ease-in-out;
  overflow: hidden;

  &.active {
    transform: translateX(0);
  }
}
.nav {
  margin: 0;
  padding: rem(60px) rem(42px) rem(60px) rem(22px);
  list-style: none;
  width: calc(100% + 20px);
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  li {
    border-top: solid 1px $grey;
    padding: rem(7px) 0;

    &:last-child {
      border-bottom: solid 1px $grey;
    }

    &.sub-nav {
      a {
        &:before {
          content: '+';
          margin-right: 10px;
          font-weight: 700;
        }
      }
    }

    a {
      display: inline-block;
      text-transform: uppercase;
      color: lighten($main-text-color, 40);
      font-size: rem(18px);
      text-decoration: none;

      &:hover {
        color: $brand-color;
      }

      &.active {
        color: lighten($main-text-color, 80);
      }
    }

    ul {
      list-style: none;
      display: none;

      li {
        border: 0 !important;
        padding: rem(10px) 0;

        &:first-child {
          padding-top: rem(20px);
        }

        a {
          text-transform: none;
          font-size: rem(14px);

          &:before {
            display: none !important;
          }
        }
      }
    }
  }
}
