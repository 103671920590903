// -----------------------------------------------------------------------------
// This file contains all styles related to the result-table components.
// -----------------------------------------------------------------------------
.result-table {
  &.scroll-left {
    .body {
      border-bottom: solid 2px $grey;

      .row:last-child {
        border: 0;
      }

      .mCSB_outside+.mCSB_scrollTools {
        right: auto;
        left: -17px;
      }
    }
  }

  .header {
    font-weight: 700;
    display: flex;
    padding-bottom: 18px;
    border-bottom: solid 2px $grey;

    .sort-by {
      color: $main-text-color;
      text-decoration: none;
      display: inline-block;
      position: relative;
      padding-right: 18px;

      &:before,
      &:after {
        border: 4px solid transparent;
        content: "";
        display: block;
        height: 0;
        right: 5px;
        top: 50%;
        position: absolute;
        width: 0;
      }

      &:before {
        border-bottom-color: $main-text-color;
        margin-top: -8px;
      }

      &:after {
        border-top-color: $main-text-color;
        margin-top: 2px;
      }
    }
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6{
    display: inline-block;
    padding-left: 10px;
  }

  .col-1 { width: 38.5%; padding-left: 0; }
  .col-2 { width: 14%; }
  .col-3 { width: 14%; }
  .col-4 { width: 14%; }
  .col-5 { width: 19.5%; }
  .col-6 { width: 19.5%; }

  .body {
    font-size: rem(9px);
    height: 376px;

    .row {
      display: flex;
      border-bottom: solid 1px $grey;

      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6 {
        min-height: 46px;
        display: flex;
        align-items: center;
      }
    }

    .checkbox {
      display: inline-block;
      height: 14px;
      width: 14px;
      padding: 0;
    }

    .mCSB_outside+.mCSB_scrollTools {
      right: -15px;
    }

    .mCSB_scrollTools {
      opacity: 1;

      .mCSB_draggerRail {
        width: 14px;
        background: #d8d8d8;
      }

      .mCSB_dragger .mCSB_dragger_bar {
        background: $brand-color;
      }
    }
  }
}
