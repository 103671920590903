/**
 * Basic typography style for copy text
 */
body, html {
  color: $main-text-color;
  font: normal 125% / 1.4 $main-font;
  font-size: $main-font-size;
}

h2 {
  font-size: rem(18px);
  text-transform: uppercase;
  font-weight: normal;
}
