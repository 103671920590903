// -----------------------------------------------------------------------------
// This file contains styles that are specific to the products-ingredients page.
// -----------------------------------------------------------------------------
.content-table {
  width: 100%;
  min-height: rem(460px);
  background: #fff;
  position: relative;

  &.empty {
    background-image: image('ico-table.png');
    background-position: center;
    background-repeat: no-repeat;
  }
}

.products-ingredients-result {
  width: 100%;
  min-height: rem(460px);
  overflow: hidden;
  background: $white;
  padding: rem(20px) rem(34px) rem(20px) rem(20px);
  position: relative;
  display: none;

  .result-table {
    text-align: center;

    .col-1 { width: 18%; padding: 0 5px; }
    .col-2 { width: 16%; padding: 0 5px; }
    .col-3 { width: 16%; padding: 0 5px; }
    .col-4 { width: 16%; padding: 0 5px; }
    .col-5 { width: 16%; padding: 0 5px; }
    .col-6 { width: 18%; padding: 0 5px; }

    .body {
      height: 284px;

      .row {
        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5,
        .col-6 {
          justify-content: center;
        }
      }
    }
  }

  .download-xls {
    color: $main-text-color;
    display: inline-block;
    background: image('ico-file-xls.png') 0 0 no-repeat;
    height: 31px;
    line-height: 31px;
    padding-left: 30px;

    margin-top: 60px;
  }
}
