// -----------------------------------------------------------------------------
// This file contains all styles related to the main content wrapper of the site/application.
// -----------------------------------------------------------------------------
.content {
  margin-top: $header-height;
  margin-bottom: $footer-height;
  padding-top: rem(54px);
  padding-bottom: rem(20px);

  .content-box {
    display: flex;

    .sidebar {
      flex-basis: 198px;
    }
  }
}
