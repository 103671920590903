// -----------------------------------------------------------------------------
// This file contains styles that are specific to the start page.
// -----------------------------------------------------------------------------
.container {
  .start-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      display: inline-block;
      width: 30%;
      border: solid 1px #fff;
      padding: 7px;
      text-decoration: none;
      color: $white;
      text-align: center;
      position: relative;

      &:hover {
        .item-wrap {
          .ico {
            background-position: 0 100%;
          }

          span {
            color: $brand-color;
          }
        }
      }

      &:before {
          content: "";
          display: block;
          padding-top: 100%;
      }

      .item-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .ico {
          display: inline-block;
          width: 100px;
          height: 104px;
          margin-bottom: 35px;
          background-position: 0 0;
          background-repeat: no-repeat;

          &.kpi-datenbank {
            background-image: image('nav-kpi-datenbank.png');
          }

          &.schlagwortsuche {
            background-image: image('nav-schlagwortsuche.png');
          }

          &.analytik {
            background-image: image('nav-analytik.png');
          }
        }

        span {
          display: block;
        }
      }
    }
  }
}
