// -----------------------------------------------------------------------------
// This file contains styles that are specific to the search page.
// -----------------------------------------------------------------------------
.search-form {
  display: flex;
  align-items: start;
  margin-bottom: 20px;

  .input-group {
    width: 76%;
    margin-right: 1%;
  }

  .selectbox-search {
    width: 22%;
    margin-left: 1%;
  }

  select {
    height: 1px;
  }


  .ss-main {
    font-size: rem(12px);

    .ss-multi-selected {
      border: none;
      border-radius: 0;
      min-height: 35px;
      padding-left: 10px;

      .ss-values {
        .ss-value{
          margin-top: 7px;
          margin-bottom: 7px;
        }
      }

      .ss-add {
        margin-top: 13px;
      }
    }
  }
}

.search-result {
  width: 100%;
  min-height: rem(460px);
  overflow: hidden;
  background: $white;
  padding: rem(20px) rem(34px) rem(20px) rem(20px);
  position: relative;
}
