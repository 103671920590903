// -----------------------------------------------------------------------------
// This file contains all styles related to the slider component.
// -----------------------------------------------------------------------------
.slider {
  visibility: hidden;

  .owl-stage-outer {
    border: solid 1px #fff;

    .owl-item {
      padding: 20px;
      height: 314px;

      .slide {
        height: 100%;
        // overflow: scroll;

        .scroll {
          height: 100%;

          .mCSB_scrollTools {
            opacity: 1;

            .mCSB_draggerRail {
              width: 14px;
            }

            .mCSB_dragger .mCSB_dragger_bar {
              background: $brand-color;
            }
          }
        }
      }

      .h100 {
        height: 100%;
        width: auto;
      }

      .w70 {
        width: 70%;
      }
    }
  }

  .owl-nav {
    .owl-prev, .owl-next {
      position: absolute;
      width: 60px;
      height: 274px;
      top: 0;
      margin-left: -60px;
      display: block !important;
      text-indent: -9999px;

      &:before {
        content: '';
        display: block;
        width: 15px;
        height: 25px;
        background: image('ico-arrow-slider.png') 0 0 no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -11px 0 0 -8px;
      }

      &:hover {
        &:before {
          background-position: 0 100%;
        }
      }
    }

    .owl-next {
      margin-left: 0;
      margin-right: -60px;
      right: 0;

      &:before {
        background-position: 100% 0;
      }

      &:hover {
        &:before {
          background-position: 100% 100%;
        }
      }
    }
  }

  .owl-dots {
    margin-top: 48px;

    .owl-dot {
      display: inline-block;
      margin-right: rem(38px);
      position: relative;
      line-height: rem(14px);
      color: $grey;

      &:before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        border: solid 1px $border-color;
        border-radius: $border-radius;
        vertical-align: top;
        margin-right: 14px;
      }

      &.active {
        color: $brand-color;

        &:before {
          background-image: image('ico-checked.png');
          background-position: 50%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
