// -----------------------------------------------------------------------------
// This file contains all styles related to the checkbox component.
// -----------------------------------------------------------------------------
$grey: #b2b2b2;

.checkbox {
  padding-left: rem(28px);
  position: relative;
  color: $grey;
  line-height: rem(14px);
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: rem(14px);
    height: rem(14px);
    top: 0;
    left: 0;
    border: solid 1px $border-color;
    border-radius: $border-radius;
    background-color: #fff;

  }

  &.active {
    color: $main-text-color;

    &:before {
      background-image: image('ico-checked.png');
      background-position: 50%;
      background-repeat: no-repeat;
    }
  }
}
