// -----------------------------------------------------------------------------
// This file contains all styles related to the dropdown component.
// -----------------------------------------------------------------------------
.dropdown {
  position: absolute;
  display: none;
  transform: translate(0, 10px);
  background-color: #fff;
  padding: 0;
  margin: 0;
  list-style: none;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  width: auto;
  border-radius: $border-radius;
  border: solid 1px $border-color;
  z-index: 1000;

  &:before {
    position: absolute;
    z-index: -1;
    content: '';
    left: 7px;
    top: -7px;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent $border-color transparent;
    background-clip: padding-box;
  }

  &:after {
    position: absolute;
    z-index: -1;
    content: '';
    left: 8px;
    top: -6px;
    border-style: solid;
    border-width: 0 5px 6px 5px;
    border-color: transparent transparent #fff transparent;
    background-clip: padding-box;
  }

  li {
    padding: 3px 30px 3px 10px;
    cursor: pointer;
    position: relative;
    min-width: 200px;

    &:after {
      content: '';
      position: absolute;
      top: 6px;
      right: 0;
      background: image('ico-arrow.png') 0 0 no-repeat;
      width: 14px;
      height: 11px;
    }

    &:hover, &.active {
      background: $brand-color;

      &:after {
        background-position: 0 100%;
      }
    }

    &:hover {
      & > ul {
        display: block;
      }
    }

    a {
      color: $main-text-color;
      text-decoration: none;
      display: block;
    }

    ul {
      position: absolute;
      display: none;
      top: -1px;
      left: 100%;
      background-color: #fff;
      padding: 0;
      margin: 0;
      list-style: none;
      box-shadow: 0 2px 10px rgba(0,0,0,0.2);
      width: auto;
      border-radius: $border-radius;
      border: solid 1px $border-color;

      li {
        ul {
          li  {
            padding-right: 10px;

            &:after  {
              display: none;
            }
          }
        }
      }
    }
  }

  & > li:first-child {
    margin-top: 6px;
  }

  & > li:last-child {
    margin-bottom: 6px;
  }
}
