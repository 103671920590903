@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins';

// 2. Vendors
@import
  './node_modules/normalize.css/normalize.css',
  './node_modules/owl.carousel/dist/assets/owl.carousel.min.css',
  './node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css',
  'vendor/slimselect';

// 3. Base stuff
@import
  'base/base',
  'base/fonts',
  'base/typography',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/footer',
  'layout/content',
  'layout/box',
  'layout/sidebar';

// 5. Components
@import
  'components/dropdown',
  'components/checkbox',
  'components/buttons',
  'components/slider',
  'components/result-table',
  'components/chart',
  'components/forms',
  'components/popup',
  'components/nav';

// 6. Page-specific styles
@import
  'pages/home',
  'pages/start',
  'pages/login',
  'pages/search',
  'pages/post-launch',
  'pages/products-ingredients';
