// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer {
  height: rem($footer-height);
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2000;
  box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.25);
  background: #fff;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    a {
      color: $second-text-color;
      font-size: rem(11px);

      &.smartcon {
        display: inline-block;
        background: image('logo-smartcon.png') 100% 0 no-repeat;
        padding-right: 106px;
        height: 28px;
        padding-top: 5px;
        text-decoration: none;
        margin-top: 1px;

        &:hover {
          background-position: 100% 100%;
        }
      }
    }
  }
}
