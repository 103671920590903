// -----------------------------------------------------------------------------
// This file contains all styles related to all form elements.
// -----------------------------------------------------------------------------
.transparent {
  label {
    color: $white;
    display: block;
    font-size: rem(14px);
    line-height: 1.85em;
    margin-bottom: rem(4px);
  }

  input {
    border: solid 1px $white;
    background: transparent;
    width: 100%;
    color: $white;
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    outline: none;
    font-family: $main-font;
    font-size: rem(14px);
    margin-bottom: rem(15px);
  }

  a {
    color: $white;
    font-size: rem(14px);

    &:hover {
      color: $brand-color;
    }
  }

  .error-message {
    border: solid 1px #b40000;
    background: #eabebe;
    padding: 14px;
    margin-bottom: rem(15px);
    opacity: 0;
    transition: opacity .25s ease-in-out;

    &.active {
      opacity: 1;
    }
  }
}

.form-content {
  width: 100%;

  input {
    border: none;
    background: $white;
    width: 100%;
    color: $main-text-color;
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    outline: none;
    font-family: $main-font;
    font-size: rem(12px);
  }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    input {
      position: relative;
      padding-left: 20px;
      flex: 1 1 auto;
      width: 1%;
    }

    button {
      background: $white;
      border: none;
      outline: none;
      cursor: pointer;
      position: relative;
      width: 50px;

      &:before {
        content: '';
        height: 100%;
        width: 50px;
        position: absolute;
        top: 0;
        right: 0;
        background: image('ico-search.png') 50% 50% no-repeat;
      }
    }
  }
}

.form-products-ingredients {
  button {
    width: 100%;
    margin-top: 10px;

    &#show-products-ingredients {
      display: none;
    }
  }
}
