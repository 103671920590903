// -----------------------------------------------------------------------------
// This file contains all styles related to the centered box wrapper of the site/application.
// -----------------------------------------------------------------------------
.box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .box-wrap {
    max-width: 350px;
  }
}
