// -----------------------------------------------------------------------------
// This file contains styles that are specific to the login page.
// -----------------------------------------------------------------------------
.login {
  .forgot-password-link {
    display: inline-block;
    margin-bottom: 36px;
  }
}

.forgot-password {
  display: none;

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.forgot-password-feedback {
  color: $white;
  font-size: rem(14px);
  display: none;

  p {
    margin-bottom: 1em;

    a {
      text-decoration: underline;
    }
  }

  a {
    color: $white;
    font-size: rem(14px);

    &:hover {
      color: $brand-color;
    }
  }
}
