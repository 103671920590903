// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  height: 100%;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $main-text-color;
    text-decoration: underline;
  }
}

body {
  background: image('bg.jpg') 0 0 no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
}

h2 {
  color: #fff;
  margin-bottom: rem(26px);
}



/*
h1{
	@include linear-gradient(to top, green, lightgreen);
	@include on-event {
		@include linear-gradient(to top, red, orange);
	}
	@include respond-to(xmobile) { width: 100% ;}
	@include respond-to(mobile) { width: 100% ;}
	@include respond-to(tablet_v) { width: 125px; }
	@include respond-to(tablet_p) { float: none; width: 900px;}
	@include respond-to(desktop) { float: none; }
}
*/

