// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
header {
  height: rem($header-height);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2001;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.25);
  background: #fff;

  .logo-wrap {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: rem(80px);

    .logo {
      color: #003796;
      font-size: rem(18px);
      line-height: 1;
      display: block;
      text-decoration: none;

      span {
        display: block;
        color: $main-text-color;
        font-size: rem(11px);
        margin-left: rem(28px);
      }
    }

    .btn-nav {
      position: absolute;
      top: rem($header-height/2 - 11px);
      left: rem(22px);
      display: inline-block;
      cursor: pointer;
      text-transform: none;
      background-color: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      outline: none;

      .hamburger-box {
        width: 26px;
        height: 22px;
        display: inline-block;
        position: relative;

        &.active {
          .hamburger-inner {
            transform: rotate(45deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::before {
              top: 0;
              opacity: 0;
              transition: top 0.075s ease,
                          opacity 0.075s 0.12s ease;
            }

            &::after {
              bottom: 0;
              transform: rotate(-90deg);
              transition: bottom 0.075s ease,
                          transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
          }
        }

        .hamburger-inner {
          display: block;
          top: 50%;
          margin-top: -2px;
          transition-duration: 0.075s;
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
          width: 26px;
          height: 4px;
          background-color: $brand-color;
          border-radius: 2px;
          position: absolute;
        }

        .hamburger-inner::before, .hamburger-inner::after {
          content: "";
          display: block;
        }

        .hamburger-inner::before {
          top: -8px;
          transition: top 0.075s 0.12s ease,
                      opacity 0.075s ease;
        }

        .hamburger-inner::after {
          bottom: -8px;
          transition: bottom 0.075s 0.12s ease,
                      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
      }
    }
  }

  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .logo-mcdonalds {
      display: block;
      height: 28px;
      width: 32px;
      background: image('logo-mcdonalds.png') 0 0 no-repeat;
      background-size: 32px 28px;
    }
  }
}
