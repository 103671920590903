// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------



/// Main font family
/// @type List
$main-font: Tahoma, Verdana, Segoe, sans-serif !default;

/// Main font size
/// @type Size
$main-font-size: 12px !default;

/// Main text color
/// @type Color
$main-text-color: #000 !default;

/// Second text color
/// @type Color
$second-text-color: #999 !default;

/// Main brand color
/// @type Color
$brand-color: #ffcd04 !default;

/// Container's maximum width
/// @type Length
$max-width: 1166px !default;

/// Header height
/// @type Height
$header-height: 70px !default;

/// Footer height
/// @type Height
$footer-height: 50px !default;



/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 480px,
  'medium': 768px,
  'large': 1024px,
  'xlarge': 1280px,
) !default;



/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '../' !default;
