// -----------------------------------------------------------------------------
// This file contains all styles related to the popup component.
// -----------------------------------------------------------------------------
.popup {
  position: absolute;
  width: calc(100% + 1px);
  min-height: rem(460px);
  top: 0;
  left: 0;
  padding: 0 64px;
  background: $dark-grey;
  display: none;
  opacity: 0;
  transform: scale(.9);
  transition: all .25s ease-in-out;
  z-index: 100;

  &.active {
    opacity: 1;
    transform: scale(1);
  }

  h3 {
    font-weight: normal;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    margin: 20px 0;
  }

  .slider {
    height: 380px;
  }

  .btn-close {
    position: absolute;
    right: 0;
    margin: 14px 14px 0 0;

    &:before {
      background-image: image('ico-close.png');
      width: 13px;
      height: 13px;
      margin: 6px;
    }
  }

  .report-link {
    float: right;
    margin-top: -16px;
    color: #fff;
    padding-left: 26px;
    display: block;
    position: relative;
    text-decoration: none;
    z-index: 2;

    &:hover {
      color: $brand-color;
    }

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 1px;
      width: 14px;
      height: 13px;
      background: image('ico-report.png') 0 0 no-repeat;
    }
  }
}
