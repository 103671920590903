@import url(../../bower_components/normalize.css/normalize.css);
/** Set up a decent box model on the root element */
html { box-sizing: border-box; }

/** Make all elements from the DOM inherit from the parent box-sizing Since `*` has a specificity of 0, it does not override the `html` value making all elements inheriting from the root box-sizing value See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
*, *::before, *::after { box-sizing: inherit; }

/** Basic styles for links */
a { color: #ccc; text-decoration: none; }

a:hover, a:active, a:focus { color: #000; text-decoration: underline; }

/* h1{ @include linear-gradient(to top, green, lightgreen); @include on-event { @include linear-gradient(to top, red, orange); }	 @include respond-to(xmobile) { width: 100% ;} @include respond-to(mobile) { width: 100% ;}	 @include respond-to(tablet_v) { width: 125px; } @include respond-to(tablet_p) { float: none; width: 900px;} @include respond-to(desktop) { float: none; }	 } */
/** Basic typography style for copy text */
body { color: #000; font: normal 125%/1.4 "Open Sans", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif; }

/** Clear inner floats */
.clearfix::after { clear: both; content: ''; display: table; }

/** Main content containers 1. Make the container full-width with a maximum width 2. Center it in the viewport 3. Leave some space on the edges, especially valuable on small screens */
.container { max-width: 1180px; /* 1 */ margin-left: auto; /* 2 */ margin-right: auto; /* 2 */ padding-left: 20px; /* 3 */ padding-right: 20px; /* 3 */ width: 100%; /* 1 */ }

/** Hide text while making it readable for screen readers 1. Needed in WebKit-based browsers because of an implementation bug; See: https://code.google.com/p/chromium/issues/detail?id=457146 */
.hide-text { overflow: hidden; padding: 0; /* 1 */ text-indent: 101%; white-space: nowrap; }

/** Hide element while making it readable for screen readers Shamelessly borrowed from HTML5Boilerplate: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133 */
.visually-hidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
