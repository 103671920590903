// -----------------------------------------------------------------------------
// This file contains all styles related to the sidebar wrapper of the site/application.
// -----------------------------------------------------------------------------
.sidebar {
  padding: 0 rem(20px) 0 0;

  .selectbox {
    margin-bottom: rem(12px);
  }

  .count {
    color: #fff;
    text-align: right;
  }
}
